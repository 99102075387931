<app-header></app-header>
<div class="outer">
  <div class="workspace personal"
       #workspace
       scrollButtonListener
       [ngClass]="{'mobile_menu': extraService.showUserMobile}"
  >
    <app-side-menu></app-side-menu>
    <div class="container-feedback">

      <div class="back_button" (click)="goBack()">
        <div class="back_icon"></div>
        <div class="back_title">Назад</div>
      </div>

      <div class="container-statistics" *ngIf="feedbackStatistics">
        <div class="statistics">
          <div class="statistic-block">
            <div class="statistic-text bold-all">{{feedbackStatistics.total}}</div>
            <div class="statistic-text">Всего<br> запросов</div>
          </div>
          <div class="statistic-block">
            <div
              class="statistics-icons"
              [ngClass]="{
                  dislike: feedbackStatistics.valid_time.value < 70,
                  normal: feedbackStatistics.valid_time.value >= 70 && feedbackStatistics.valid_time.value <= 90,
                  like: feedbackStatistics.valid_time.value > 90
                }"
            ></div>
            <div class="statistic-text bold">{{feedbackStatistics.valid_time.value}}%</div>
            <div class="statistic-text">Отвечено<br> вовремя</div>
          </div>
          <div class="statistic-block">
            <div
              class="statistics-icons"
              [ngClass]="{
                  dislike: feedbackStatistics.wrong_time.value > 50,
                  normal: feedbackStatistics.wrong_time.value >= 20 && feedbackStatistics.wrong_time.value <= 50,
                  like: feedbackStatistics.wrong_time.value < 20
                }"
            ></div>
            <div class="statistic-text bold">{{feedbackStatistics.wrong_time.value}}%</div>
            <div class="statistic-text">Отвечено<br> не вовремя</div>
          </div>
          <div class="statistic-block">
            <div
              class="statistics-icons"
              [ngClass]="{
                  dislike: feedbackStatistics.not_answered.value > 20,
                  normal: feedbackStatistics.not_answered.value >= 5 && feedbackStatistics.not_answered.value <= 20,
                  like: feedbackStatistics.not_answered.value < 5
                }"
            ></div>
            <div class="statistic-text bold">{{feedbackStatistics.not_answered.value}}%</div>
            <div class="statistic-text">Без<br> ответа</div>
          </div>
        </div>
        <div class="statistics bottom">
          <div class="statistic-text down">
            Среднее время ответа:
            <b>{{feedbackStatistics.avg_time}} ч.</b>
          </div>
          <div class="statistic-text down">
            Средняя оценка:
            <b>{{feedbackStatistics.avg_rating}}</b>
            <img class="icon-star" src="assets/images/icons/star-statistics.svg">
          </div>
        </div>
      </div>

      <div class="input_container">
        <input placeholder="Введите название документа" [(ngModel)]="searchStringDocument" (keydown.enter)="searchFeedback()">
        <div class="input_button_container">
          <div class="input_button delete" *ngIf="!!searchStringDocument" (click)="clearSearchFeedback()"></div>
          <div class="input_button search" (click)="searchFeedback()"></div>
        </div>
      </div>

      <form [formGroup]="formFeedback" class="form_filter" *ngIf="formFeedback">
        <div class="form-container">

          <mat-select class="custom_select for-feedback-management" placeholder="Выберите Отдел" formControlName="department" (selectionChange)="searchFeedback()">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let department of department"
              [value]="department.id">
              {{ department.title }}
            </mat-option>
          </mat-select>

          <mat-select class="custom_select for-feedback-management" placeholder="Выберите Тему" formControlName="theme" (selectionChange)="searchFeedback()">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let theme of theme"
              [value]="theme.id">
              {{ theme.title }}
            </mat-option>
          </mat-select>

          <mat-select class="custom_select for-feedback-management" placeholder="Выберите Подтему" formControlName="subtheme" (selectionChange)="searchFeedback()">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let subtheme of subtheme"
              [value]="subtheme.id">
              {{ subtheme.title }}
            </mat-option>
          </mat-select>

          <mat-select class="custom_select for-feedback-management" placeholder="Выберите Статус" formControlName="status" (selectionChange)="searchFeedback()">
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let status of status"
              [value]="status.id">
              {{ status.title }}
            </mat-option>
          </mat-select>

        </div>
        <div class="button-container">
          <div class="button" (click)="searchFeedback()">Применить</div>
        </div>
      </form>
      <div class="card" *ngIf="users">
        <app-management-card-feedback *ngFor="let request of requests" [feedback]="request" [users]="users"></app-management-card-feedback>
      </div>
      <app-scroll-to-top [workspace]="workspace"></app-scroll-to-top>
    </div>

  </div>
</div>

