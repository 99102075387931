import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from 'services';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class BaseService {
    private readonly root = environment.url + 'api/';

    constructor(
        private httpClient: HttpClient,
        private storage: StorageService,
      ) {}

    getToken(): string {
        const token = this.storage.getValue('token');
        return 'Token ' + token;
    }

    private readonly documentBannersGetUrl = this.root + 'main/banners/'

    private readonly documentViewedUrl = this.root + 'documents/viewed/';
    private readonly documentSearchUrl = this.root + 'documents/search/';
  private readonly baseViewScormUrl = this.root + 'documents/scorm/view/'

    private readonly testAvailableUrl = this.root + 'tests/available/';
    private readonly testStartUrl = this.root + 'tests/attempt/start/';
    private readonly testFinishUrl = this.root + 'tests/attempt/finish/';
    private readonly testAttemptGetUrl = this.root + 'tests/attempt/get/';
    private readonly testAttemptsListGetUrl = this.root + 'tests/attempt/list/';

    private readonly attestationTestAvailableUrl = this.root + 'attestation-tests/available/';
    private readonly attestationTestStartUrl = this.root + 'attestation-tests/attempt/start/';
    private readonly attestationTestQuestionGetUrl = this.root + 'attestation-tests/question/get/';
    private readonly attestationTestQuestionSaveUrl = this.root + 'attestation-tests/answer/save/';
    private readonly attestationTestFinishUrl = this.root + 'attestation-tests/attempt/finish/';
    private readonly attestationTestAttemptGetUrl = this.root + 'attestation-tests/attempt/get/';

  private readonly testGetUrl = this.root + 'tests/get/';
  private readonly testProfileEditUrl = this.root + 'tests/profile/edit/';
  private readonly attestationTestGetUrl = this.root + 'attestation-test/get/';
  private readonly attestationTestProfileEditUrl = this.root + 'attestation-tests/profile/edit/';

  private readonly feedbackRequestListUrl = this.root + 'feedback/request/list/';
  private readonly feedbackRequestGetUrl = this.root + 'feedback/request/get/';
  private readonly feedbackParamsUrl = this.root + 'feedback/params/';
  private readonly feedbackCreateUrl = this.root + 'feedback/request/create/';
  private readonly feedbackFinishUrl = this.root + 'feedback/request/update/';
  private readonly feedbackMessageCreateUrl = this.root + 'feedback/message/create/';

  private readonly systemMessageSendUrl = this.root + 'system-messages/view/';
  private readonly systemMessageAuthUrl = this.root + 'system-messages/get/';

  private readonly ratingParamsUrl = this.root + 'rating/params/';
  private readonly ratingLeadersUrl = this.root + 'rating/leaders/';
  private readonly ratingGetUrl = this.root + 'rating/rating/';

    getBanners() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.getToken(),
          });


        return this.httpClient.post(
            this.documentBannersGetUrl,
            null,
            { headers },
        );
    }


    getDocumentList(section: string, offset: number, limit: number, id?: number | string) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      if (id !== 'null') {
        return this.httpClient.post(
          this.root + 'documents/' + section + '/' + 'list/',
          { offset: offset, limit: limit, id: id },
          { headers }
        )
      } else {
        return this.httpClient.post(
          this.root + 'documents/' + section + '/' + 'list/',
          { offset: offset, limit: limit },
          { headers }
        );
      }
    }

    getDocumentViewed(docId: any) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.documentViewedUrl,
        { id: docId },
        { headers }
      );
    }

    getDocument(section: string, docId: number) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.root + 'documents/' + section + '/get/',
        { id: docId },
        { headers }
      );
    }

    documentSearch(query: string, offset: number, limit: number, section?: string) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      });

      if (section) {
        return this.httpClient.post(
          this.documentSearchUrl,
          { query: query, offset: offset, limit: limit, section: section },
          { headers }
        );
      } else {
        return this.httpClient.post(
          this.documentSearchUrl,
          { query: query, offset: offset, limit: limit },
          { headers }
        );
      }
    }

  likeBlock(block_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `documents/element/like/${block_id}/`,
      null,
      { headers },
    );
  }

  dislikeBlock(block_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `documents/element/dislike/${block_id}/`,
      null,
      { headers },
    );
  }

  downloadFileArchive(block_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.root + `documents/element/download/${block_id}/`,
      null,
      { headers },
    );
  }

    testAvailable(testId: number) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.testAvailableUrl,
        { test_id: testId },
        { headers }
      );
    }

    testStart(testId: number) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.testStartUrl,
        { id: testId },
        { headers }
      );
    }

    testFinish(data: any) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.testFinishUrl,
        data,
        { headers }
      );
    }

    testAttemptGet(attemptId: number) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.testAttemptGetUrl,
        { id: attemptId },
        { headers }
      );
    }

  attestationTestAttemptGet(attemptId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.attestationTestAttemptGetUrl,
      { id: attemptId },
      { headers }
    );
  }

    testAttemptsListGet(testId: number) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.testAttemptsListGetUrl,
        { id: testId },
        { headers }
      );
    }

    attestationTestAvailable(testId: number) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken(),
      });

      return this.httpClient.post(
        this.attestationTestAvailableUrl,
        { test_id: testId },
        { headers }
      );
    }

  attestationTestStart(testId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.attestationTestStartUrl,
      { id: testId },
      { headers }
    );
  }

  attestationTestQuestionGet(attemptId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.attestationTestQuestionGetUrl,
      { attempt_id: attemptId },
      { headers }
    );
  }

  attestationTestQuestionSave(answers) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.attestationTestQuestionSaveUrl,
      answers[0],
      { headers }
    );
  }

  attestationTestFinish(attemptId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.attestationTestFinishUrl,
      { attempt_id: attemptId },
      { headers }
    );
  }

  testGet(testId: number, section: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    if (section === 'attestation') {
      return this.httpClient.post(
        this.attestationTestGetUrl,
        { id: testId },
        { headers }
      );
    } else {
      return this.httpClient.post(
        this.testGetUrl,
        { id: testId },
        { headers }
      );
    }
  }

  testProfileEdit(profileId: number, type: string, section: string, deadline?: number | null, numAttempts?: number | null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    if (type === 'deadline') {
      if (section === 'attestation') {
        return this.httpClient.post(
          this.attestationTestProfileEditUrl,
          { id: profileId, deadline: deadline },
          { headers }
        );
      } else {
        return this.httpClient.post(
          this.testProfileEditUrl,
          { id: profileId, deadline: deadline },
          { headers }
        );
      }
    } else {
      if (section === 'attestation') {
        return this.httpClient.post(
          this.attestationTestProfileEditUrl,
          { id: profileId, num_attempts: numAttempts },
          { headers }
        );
      } else {
        return this.httpClient.post(
          this.testProfileEditUrl,
          { id: profileId, num_attempts: numAttempts },
          { headers }
        );
      }
    }

  }

  baseViewScorm(id: number, action: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.baseViewScormUrl,
      {id: id, action: action},
      { headers }
    )
  }

  feedbackList(limit: number, offset: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.feedbackRequestListUrl,
      { limit: limit, offset: offset },
      { headers }
    );
  }

  feedbackGet(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });

    return this.httpClient.post(
      this.feedbackRequestGetUrl,
      { id: id },
      { headers }
    );
  }

  feedbackParams(department?, theme?) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.feedbackParamsUrl,
      { department: department, theme: theme },
      { headers }
    );
  }

  feedbackCreate(theme: number, subthemeId: number, title: string, text: string, files: File[]) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });

    const formData = new FormData()
    formData.append('theme', String(theme))
    formData.append('subtheme', String(subthemeId))
    formData.append('title', title)
    formData.append('text', text)
    files.forEach((file) => formData.append('files', file))

    return this.httpClient.post(
      this.feedbackCreateUrl,
      formData,
      { headers }
    );
  }

  feedbackMessageCreate(id: number, text: string, files: File[]) {
    const headers = new HttpHeaders({
      'Authorization': this.getToken(),
    });
    const formData = new FormData()
    formData.append('request', String(id))
    formData.append('text', text)
    files.forEach((file) => formData.append('files', file))

    return this.httpClient.post(
      this.feedbackMessageCreateUrl,
      formData,
      { headers }
    );
  }

  feedbackFinish(id: number, rating: number | null, is_over: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.feedbackFinishUrl,
      { id: id, rating: rating, is_over: is_over },
      { headers }
    );
  }

  systemMessageSend(message_id: number, doc_id?: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.systemMessageSendUrl,
      { message_id: message_id, doc_id: doc_id},
      { headers }
    );
  }

  systemMessageAuth(reference: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.systemMessageAuthUrl + reference + '/',
      {},
      { headers }
    );
  }

  ratingParams() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.ratingParamsUrl,
      null,
      { headers }
    );
  }

  ratingGet( limit: number, offset: number, period: string, filters = null, search: string ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.ratingGetUrl,
      { limit: limit, offset: offset, period: period, filters: filters, search: search },
      { headers }
    );
  }

  ratingLeaders( limit: number, offset: number, filters = null ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.ratingLeadersUrl,
      { limit: limit, offset: offset, filters: filters },
      { headers }
    );
  }

}
