import {Component, OnInit, ViewChild} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { DialogService, ExtraService } from 'services'
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FeedbackDialogComponent } from 'components/dialogs'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class FeedbackComponent implements OnInit {

  @ViewChild('workspace') workspace;

  showPrev = 'show';

  offset: number = 0;
  limit: number = 10;
  feedbackList: undefined;
  endScroll: boolean = false;

  startItem: any

  constructor(
    public extraService: ExtraService,
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
  }

  paginationOn() {
    this.offset += this.limit;
    this.baseService.feedbackList(this.limit, this.offset).subscribe((response: any) => {
      Array.prototype.push.apply(this.feedbackList, response);
      if (response.length < this.limit) {
        this.endScroll = true;
      }
    });
  }

  newFeedback() {
    let data = {
      'title': 'Новый запрос',
      showForm: true,
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {data});

    dialogRef.afterClosed().subscribe( () => {
      this.getFeedbackList()
    })
  }

  getFeedbackList() {
    this.offset = 0;
    this.limit = 10;
    this.baseService.feedbackList(this.limit, this.offset).subscribe( (response: any) => {
      this.feedbackList = response;
    })
  }

  goFeedbackManagement() {
    this.router.navigate(['feedback-management'])
  }

  ngOnInit() {
    this.getFeedbackList()
  }
}
