import {Component, Inject, OnInit} from '@angular/core'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BaseService, ManagementApiService } from 'services/api'
import { AlertDialogComponent } from 'components/dialogs'
import { DialogService } from 'services'
import {FeedbackManagementService} from "components/pages/feedback/feedback-management/feedback-management.service";

@Component({
  selector: 'app-feedback-update-dialog',
  templateUrl: './feedback-update-dialog.component.html',
  styleUrls: ['./feedback-update-dialog.component.scss']
})

export class FeedbackUpdateDialogComponent implements OnInit {

  public searchFilterCtrl: FormControl = new FormControl();
  selectSearchUpdateString = '';

  formFeedback: FormGroup = new FormGroup( {
    'department': new FormControl(null,[Validators.required]),
    'theme': new FormControl(null, [Validators.required]),
    'subtheme': new FormControl(null,[Validators.required]),
    'moderator': new FormControl(null, [Validators.required])
  })
  subtheme = undefined;
  department = undefined;
  theme = undefined;
  feedback: any;
  moderator = undefined
  title: string;

  inactiveSubmit = false

  constructor(
    public dialogRef: MatDialogRef<FeedbackUpdateDialogComponent>,
    private baseService: BaseService,
    private dialogService: DialogService,
    private managementService: ManagementApiService,
    private feedbackManagementService: FeedbackManagementService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'title': string, 'feedback': any },
  ) {
    ({
      title: this.title,
      feedback: this.feedback,
    } = this.dialogData);
  }

  paramsFilters(target) {
    switch (target) {
      case 'department':
        this.formFeedback.get('theme').setValue(null)
        this.formFeedback.get('subtheme').setValue(null)
        break
      case 'theme':
        this.formFeedback.get('subtheme').setValue(null)
        break
      default:
        break
    }
    this.updateFeedbackParams()
  }

  updateFeedbackParams() {
    this.baseService.feedbackParams(this.formFeedback.value.department, this.formFeedback.value.theme).subscribe( (response: any) => {
      this.department = response.department;
      this.theme = response.theme;
      this.subtheme = response.subtheme;
    })
  }

  updateFeedback() {
    let button = document.getElementById('buttonTemplate')
    let preloader = document.getElementById('preloaderTemplate')
    const dataAlert = {
      title: '',
      text: '',
      confirmButtonText: 'OK'
    }
    if(!this.inactiveSubmit) {
      this.inactiveSubmit = true
      if (this.formFeedback.valid) {
        this.feedbackManagementService.managementFeedbackUpdate(this.feedback.id, this.formFeedback.value.subtheme, this.formFeedback.value.moderator).subscribe((response) => {
          dataAlert.title = 'Запрос отправлен'
          this.dialogService.openDialog(AlertDialogComponent, dataAlert);
          this.dialogRef.close(response);
          this.inactiveSubmit = false
          button.style.display = 'flex'
          preloader.style.display = 'none'
        }, () => {
          dataAlert.title = 'Произошла ошибка'
          this.dialogService.openDialog(AlertDialogComponent, dataAlert);
          this.dialogRef.close(false);
          this.inactiveSubmit = false
          button.style.display = 'flex'
          preloader.style.display = 'none'
        })
      } else {
        dataAlert.title = 'Произошла ошибка'
        dataAlert.text = 'Проверьте правильность заполнения полей. Все поля должны быть заполнены.'
        this.dialogService.openDialog(AlertDialogComponent, dataAlert);
        this.inactiveSubmit = false
        button.style.display = 'flex'
        preloader.style.display = 'none'
      }
    }
  }

  ngOnInit() {
    this.baseService.feedbackParams().subscribe( (response: any) => {
      this.moderator = this.feedback.moderator
      this.formFeedback.get('department').setValue(response.department.find(x => x.title === this.feedback.path[0]).id)
      this.formFeedback.get('theme').setValue(response.theme.find(x => x.title === this.feedback.path[1]).id)
      this.formFeedback.get('subtheme').setValue(response.subtheme.find(x => x.title === this.feedback.path[2]).id)
      if (this.moderator) {
        this.formFeedback.get('moderator').setValue(this.feedback.users.find(x => x.id === this.feedback.moderator).id)
      }
      this.updateFeedbackParams()
    })
  }

}
