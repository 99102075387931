import { Injectable } from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StorageService} from "services";
import {environment} from "../../../../../environments/environment";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class FeedbackManagementService {

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService,
  ) {}

  private readonly root = environment.url + 'api/';
  private readonly managementFeedbackListUrl = this.root + 'mng/feedback/request/list';
  private readonly managementFeedbackParamsUrl = this.root + 'mng/feedback/params';
  private readonly managementFeedbackStatisticsUrl = this.root + 'mng/feedback/statistics';


  $updated: Subject<any> = new Subject()
  getToken(): string {
    const token = this.storage.getValue('token');
    return 'Token ' + token;
  }

  managementFeedbackList(search = '', theme?: number, department?: string, subtheme?: string, status?: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackListUrl,
      { query: search, theme: theme, department: department, subtheme: subtheme, status: status },
      { headers },
    );
  }

  managementFeedbackParams() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackParamsUrl,
      null,
      { headers }
    );
  }

  managementFeedbackStatistics() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken(),
    });
    return this.httpClient.post(
      this.managementFeedbackStatisticsUrl,
      null,
      { headers },
    );
  }

  managementFeedbackUpdate(feedbackId: number, subtheme: number, moderator: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.getToken()
    })
    return this.httpClient.post(
      this.root + `mng/feedback/request/${feedbackId}/update`,
      { subtheme: subtheme, moderator: moderator },
      { headers }
    )
  }

}
