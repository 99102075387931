import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {ExtraService, DialogService, ManagementService} from 'services';
import { ManagementApiService } from 'services/api';
import { AlertDialogComponent } from 'components/dialogs';
import {EMPTY, from} from "rxjs";
import {concatMap, map, tap} from "rxjs/operators";


@Component({
  selector: 'app-management-elements-dialog',
  templateUrl: './management-elements-dialog.component.html',
  styleUrls: ['./management-elements-dialog.component.scss'],
})

export class ManagementElementsDialogComponent implements OnInit, OnDestroy {

  @ViewChild('formAdd') formAdd

  docId = undefined;
  elementId = undefined;
  formElements = undefined;
  formFiles: FormGroup;
  formFilters: FormGroup;
  files = undefined;
  type = undefined;
  controlsElement = undefined;
  controlsFile = undefined;
  controlsFilter = undefined;
  section = undefined;
  arrFiles = undefined;
  arrFilters = undefined;

  inactiveSubmit = false

  optionType$: any;

  showFilterForm = false;
  formFilter = undefined;

  constructor(
    private dialogRef: MatDialogRef<ManagementElementsDialogComponent>,
    private router: Router,
    private formBuilder: FormBuilder,
    private extraService: ExtraService,
    private dialogService: DialogService,
    private managementApiService: ManagementApiService,
    private managementService: ManagementService,
    private matDialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA) private dialogData: {
      docId: string,
      elementId: string,
      formElements: any,
      formFiles: any,
      formFilters: any,
      type: any,
      controlsElement: any,
      controlsFile: any,
      controlsFilter: any,
      section: any,
      arrFiles: any,
      arrFilters: any },
  ) {
    ({
      docId: this.docId,
      elementId: this.elementId,
      formElements: this.formElements,
      formFiles: this.formFiles,
      formFilters: this.formFilters,
      type: this.type,
      controlsElement: this.controlsElement,
      controlsFile: this.controlsFile,
      controlsFilter: this.controlsFilter,
      section: this.section,
      arrFiles: this.arrFiles,
      arrFilters: this.arrFilters
    } = this.dialogData);
  }

  optionType = undefined

  deleteCopyArrFiles = []

  createFileElement() {
    switch (this.type) {
      case 'add-question':
      case 'edit-question':
      case 'add-question-attestation':
      case 'edit-question-attestation':
        if (this.optionType === 'matching') {
          this.arrFiles.push(
            {
              form: this.formBuilder.group(this.managementService.makeFormGroupElements(this.controlsFile)),
              type: 'create'
            }
          )
        } else {
          this.arrFiles.push(
            {
              form: this.formBuilder.group(this.formFiles),
              type: 'create'
            }
          )
        }
        break
      default:
        this.arrFiles.push(
          {
            form: this.formBuilder.group(this.formFiles),
            type: 'create'
          }
        );
        break
    }
  }

  createFilterElement() {
    this.showFilterForm = true
    this.formFilter = this.formBuilder.group(this.formFilters)
  }

  deleteFileElement(form, index) {
    if (form.id) {
      switch (this.type) {
        case 'add-elements':
        case 'edit-elements':
          this.managementApiService.managementDeleteFileElement(form.id).subscribe();
          break
        case 'add-question':
        case 'edit-question':
          this.managementApiService.managementDeleteOption(form.id).subscribe();
          break
        case 'add-question-attestation':
        case 'edit-question-attestation':
          this.managementApiService.managementDeleteOptionAttestation(form.id).subscribe()
          break
      }
    }
    this.arrFiles.splice(index, 1);
  }

  deleteFilterElement(id, index) {
    if (this.section === 'business') {
      this.managementApiService.managementDeleteElementFilter(id).subscribe()
      this.arrFilters.splice(index, 1);
    } else {
      this.managementApiService.managementDeleteFilterQuestionAttestation(id).subscribe()
      this.arrFilters.splice(index, 1);
    }
  }

  closeFilterForm() {
    this.formFilter = undefined
    this.showFilterForm = false
  }

  checkType(type) {
    let resultCheckObj
    switch (type) {
      case 'add-elements':
        resultCheckObj = {
          viewSubForm: true,
          titleSubFormContainer: 'Файлы',
          titleSubFormAddButton: 'Добавить файлы',
          titleFormSubmitButton: 'Добавить элемент',
          viewSubFilterForm: true,
          titleSubFilterFormContainer: 'Фильтры',
          titleSubFilterFormAddButton: 'Добавить фильтр'
        }
        break
      case 'edit-elements':
        resultCheckObj = {
          viewSubForm: true,
          titleSubFormContainer: 'Файлы',
          titleSubFormAddButton: 'Добавить файлы',
          titleFormSubmitButton: 'Обновить элемент',
          viewSubFilterForm: true,
          titleSubFilterFormContainer: 'Фильтры',
          titleSubFilterFormAddButton: 'Добавить фильтр'
        }
        break
      case 'add-question':
        resultCheckObj = {
          viewSubForm: true,
          viewSubFilterForm: false,
          titleSubFormContainer: 'Ответы',
          titleSubFormAddButton: 'Добавить Ответы',
          titleFormSubmitButton: 'Добавить Вопрос'
        }
        break
      case 'add-question-attestation':
        resultCheckObj = {
          viewSubForm: true,
          titleSubFormContainer: 'Ответы',
          titleSubFormAddButton: 'Добавить Ответы',
          titleFormSubmitButton: 'Добавить Вопрос',
          viewSubFilterForm: true,
          titleSubFilterFormContainer: 'Фильтры',
          titleSubFilterFormAddButton: 'Добавить фильтр'
        }
        break
      case 'edit-question':
        resultCheckObj = {
          viewSubForm: true,
          viewSubFilterForm: false,
          titleSubFormContainer: 'Ответы',
          titleSubFormAddButton: 'Добавить Ответы',
          titleFormSubmitButton: 'Обновить Вопрос'
        }
        break
      case 'edit-question-attestation':
        resultCheckObj = {
          viewSubForm: true,
          titleSubFormContainer: 'Ответы',
          titleSubFormAddButton: 'Добавить Ответы',
          titleFormSubmitButton: 'Обновить Вопрос',
          viewSubFilterForm: true,
          titleSubFilterFormContainer: 'Фильтры',
          titleSubFilterFormAddButton: 'Добавить фильтр'
        }
        break
      case 'add-filter':
      case 'add-banner-filter':
      case 'add-notification-filter':
        resultCheckObj = {
          viewSubForm: false,
          viewSubFilterForm: false,
          titleSubFormContainer: '',
          titleSubFormAddButton: '',
          titleFormSubmitButton: 'Добавить фильтр'
        }
        break
      case 'edit-filter':
      case 'edit-banner-filter':
        resultCheckObj = {
          viewSubForm: false,
          viewSubFilterForm: false,
          titleSubFormContainer: '',
          titleSubFormAddButton: '',
          titleFormSubmitButton: 'Обновить фильтр'
        }
        break
      case 'add-banner-image':
        resultCheckObj = {
          viewSubForm: false,
          viewSubFilterForm: false,
          titleSubFormContainer: '',
          titleSubFormAddButton: '',
          titleFormSubmitButton: 'Добавить изображение'
        }
        break
      case 'edit-banner-image':
        resultCheckObj = {
          viewSubForm: false,
          viewSubFilterForm: false,
          titleSubFormContainer: '',
          titleSubFormAddButton: '',
          titleFormSubmitButton: 'Обновить изображение'
        }
        break
      case 'add-file-notification':
        resultCheckObj = {
          viewSubForm: true,
          viewSubFilterForm: false,
          titleSubFormContainer: 'Файлы',
          titleSubFormAddButton: 'Добавить файл',
          titleFormSubmitButton: 'Загрузить'
        }
        break
      default:
        console.log('Несуществует полученного типа')
        break
    }
    return resultCheckObj;
  }

  changeDialogData(type) {
    let data = {}
    switch (type) {
      case 'add-elements':
        data = {
          title: 'Элемент успешно добавлен',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-elements':
        data = {
          title: 'Элемент успешно обновлен',
          confirmButtonText: 'OK'
        }
        break
      case 'add-question':
      case 'add-question-attestation':
        data = {
          title: 'Вопрос успешно добавлен',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-question':
      case 'edit-question-attestation':
        data = {
          title: 'Вопрос успешно обновлен',
          confirmButtonText: 'OK'
        }
        break
      case 'add-filter':
        data = {
          title: 'Фильтр успешно добавлен',
          confirmButtonText: 'OK'
        }
        break
      case 'add-banner-image':
        data = {
          title: 'Изображение баннера успешно добавлено',
          confirmButtonText: 'OK'
        }
        break
      case 'add-banner-filter':
        data = {
          title: 'Фильтр баннера успешно добавлен',
          confirmButtonText: 'OK'
        }
        break
      case 'add-file-notification':
        data = {
          title: 'Файлы добавлены успешно',
          confirmButtonText: 'OK'
        }
        break
      case 'add-notification-filter':
        data = {
          title: 'Фильтр успешно добавлен',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-filter':
        data = {
          title: 'Фильтр успешно обновлен',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-banner-image':
        data = {
          title: 'Изображение баннера успешно обновлено',
          confirmButtonText: 'OK'
        }
        break
      case 'edit-banner-filter':
        data = {
          title: 'Фильтр баннера успешно обновлен',
          confirmButtonText: 'OK'
        }
        break
      case 'odd-error':
        data = {
          title: 'Должно быть чётное колличество ответов',
          confirmButtonText: 'OK'
        }
        break
      case 'over-ones-error':
        data = {
          title: 'Ответов должно быть не больше одного',
          confirmButtonText: 'OK'
        }
        break
      case 'error':
        data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        break
    }
    return data;
  }

  collectForms() {
    const formData: FormData = new FormData();
    this.inactiveSubmit = true;
    switch (this.type) {
      case 'add-elements':
        this.managementApiService.managementCreateElement(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe((response: any) => {
          if (this.arrFiles.length > 0) {
            this.arrFiles.forEach((file) => {
              const formData: FormData = new FormData();
              this.managementApiService.managementCreateFileElement(this.managementService.collectObject(this.type, formData, file.form), response.id).subscribe(() => {
                this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
                  this.managementApiService.managementGetElements(this.docId).subscribe((response: any) => {
                    this.dialogRef.close({elements: response});
                    this.matDialogService.closeAll();
                  })
                }, () => {
                  this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
                  this.inactiveSubmit = false;
                });
              });
            });
            return
          }
          if (this.formFilter) {
            const formData: FormData = new FormData();
            this.managementApiService.managementCreateElementFilter(this.managementService.collectObject('add-filter', formData, this.formFilter), response.id).subscribe();
          }
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetElements(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'edit-elements':
        formData.append('document_id', this.docId);
        this.managementApiService.managementUpdateElement(this.managementService.collectObject(this.type, formData, this.formElements), this.elementId).subscribe(() => {
          if (this.arrFiles.length > 0) {
            this.arrFiles.forEach((file) => {
              const formData: FormData = new FormData();
              switch (file.type) {
                case 'create':
                  this.managementApiService.managementCreateFileElement(this.managementService.collectObject(this.type, formData, file.form), this.elementId).subscribe(() => {
                    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
                      this.managementApiService.managementGetElements(this.docId).subscribe((response: any) => {
                        this.dialogRef.close({elements: response});
                        this.matDialogService.closeAll();
                      })
                    });
                  }, () => {
                    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
                    this.inactiveSubmit = false;
                  });
                  break
                case 'update':
                  this.managementApiService.managementUpdateFileElement(this.managementService.collectObject(this.type, formData, file.form), file.id).subscribe(() => {
                    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
                      this.managementApiService.managementGetElements(this.docId).subscribe((response: any) => {
                        this.dialogRef.close({elements: response});
                        this.matDialogService.closeAll();
                      })
                    });
                  }, () => {
                    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
                    this.inactiveSubmit = false;
                  });
                  break
              }
            });
            return
          }
          console.log(this.formFilter)
          if (this.formFilter) {
            const formData: FormData = new FormData();
            this.managementApiService.managementCreateElementFilter(this.managementService.collectObject('add-filter', formData, this.formFilter), this.elementId).subscribe();
          }
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetElements(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'add-question':
        switch (this.formElements.get('type').value) {
          case 'input':
            if (this.arrFiles.length > 1) {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('over-ones-error'));
              this.inactiveSubmit = false;
              return;
            }
            break
        }
        this.managementApiService.managementCreateQuestion(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe((response: any) => {
          this.arrFiles.forEach((file) => {
            const formData: FormData = new FormData();
            this.managementApiService.managementCreateOption(this.managementService.collectObject(this.type, formData, file.form), response.id).subscribe();
          });
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetQuestions(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'add-question-attestation':
        switch (this.formElements.get('type').value) {
          case 'input':
            if (this.arrFiles.length > 1) {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('over-ones-error'));
              this.inactiveSubmit = false;
              return;
            }
            break
        }
        this.managementApiService.managementCreateQuestionAttestation(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe((response: any) => {
          const arrFiles$ = from(this.arrFiles)
          let formData: FormData
          arrFiles$.pipe(
            tap(() => {
              formData = new FormData();
            }),
            concatMap((file: any) => this.managementApiService.managementCreateOptionAttestation(this.managementService.collectObject(this.type, formData, file.form), response.id))
          ).subscribe()
          if (this.formFilter) {
            const formData: FormData = new FormData();
            this.managementApiService.managementCreateFilterQuestionAttestation(this.managementService.collectObject('add-filter', formData, this.formFilter), response.id).subscribe();
          }
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetQuestionsAttestation(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'edit-question':
        switch (this.formElements.get('type').value) {
          case 'input':
            if (this.arrFiles.length > 1) {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('over-ones-error'));
              this.inactiveSubmit = false;
              return;
            }
            break
        }
        this.managementApiService.managementUpdateQuestion(this.managementService.collectObject(this.type, formData, this.formElements), this.elementId).subscribe(() => {
          this.deleteCopyArrFiles.forEach((option) => {
            this.managementApiService.managementDeleteOption(option.id).subscribe();
          })
          this.arrFiles.forEach((option) => {
            const formData: FormData = new FormData();
            switch (option.type) {
              case 'create':
                this.managementApiService.managementCreateOption(this.managementService.collectObject(this.type, formData, option.form), this.elementId).subscribe();
                break
              case 'update':
                this.managementApiService.managementUpdateOption(this.managementService.collectObject(this.type, formData, option.form), option.id).subscribe();
                break
            }
          });
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetQuestions(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'edit-question-attestation':
        switch (this.formElements.get('type').value) {
          case 'input':
            if (this.arrFiles.length > 1) {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('over-ones-error'));
              this.inactiveSubmit = false;
              return;
            }
            break
        }
        this.managementApiService.managementUpdateQuestionAttestation(this.managementService.collectObject(this.type, formData, this.formElements), this.elementId).subscribe(() => {
          this.deleteCopyArrFiles.forEach((option) => {
            this.managementApiService.managementDeleteOptionAttestation(option.id).subscribe();
          })
          const arrFiles$ = from(this.arrFiles)
          let formData: FormData
          arrFiles$.pipe(
            tap(() => {
              formData = new FormData();
            }),
            concatMap((option: any) => {
              switch (option.type) {
                case 'create':
                  if (!option.form.value.is_right && option.form.value.score > 0) {
                    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
                    this.inactiveSubmit = false;
                    return EMPTY;
                  }
                  return this.managementApiService.managementCreateOptionAttestation(this.managementService.collectObject(this.type, formData, option.form), this.elementId);
                case 'update':
                  if (!option.form.value.is_right && option.form.value.score > 0) {
                    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
                    this.inactiveSubmit = false;
                    return EMPTY;
                  }
                  return this.managementApiService.managementUpdateOptionAttestation(this.managementService.collectObject(this.type, formData, option.form), option.id);
                default:
                  return EMPTY;
              }
            })
          ).subscribe()
          if (this.formFilter) {
            const formData: FormData = new FormData();
            this.managementApiService.managementCreateFilterQuestionAttestation(this.managementService.collectObject('add-filter', formData, this.formFilter), this.elementId).subscribe();
          }
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetQuestionsAttestation(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'add-filter':
        this.managementApiService.managementCreateFilter(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetFilters(this.docId).subscribe((response: any) => {
              this.dialogRef.close({filters: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'edit-filter':
        this.managementApiService.managementUpdateFilter(this.managementService.collectObject(this.type, formData, this.formElements), this.elementId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetFilters(this.docId).subscribe((response: any) => {
              this.dialogRef.close({filters: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'add-banner-image':
        this.managementApiService.managementCreateBannerImage(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetBannerImages(this.docId).subscribe((response: any) => {
              this.dialogRef.close({elements: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'edit-banner-image':
        this.managementApiService.managementUpdateBannerImage(this.managementService.collectObject(this.type, formData, this.formElements), this.elementId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetBannerImages(this.docId).subscribe((response: any) => {
              this.dialogRef.close({filters: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'add-banner-filter':
        this.managementApiService.managementCreateBannerFilter(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetBannerFilters(this.docId).subscribe((response: any) => {
              this.dialogRef.close({filters: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'edit-banner-filter':
        this.managementApiService.managementUpdateBannerFilter(this.managementService.collectObject(this.type, formData, this.formElements), this.elementId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetBannerFilters(this.docId).subscribe((response: any) => {
              this.dialogRef.close({filters: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
      case 'add-file-notification':
        if (this.arrFiles.length > 0) {
          this.arrFiles.forEach((file) => {
            const formData: FormData = new FormData();
            this.managementApiService.managementGetNotificationsFileCreate(this.managementService.collectObject(this.type, formData, file.form), this.docId).subscribe(() => {
              this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
                this.managementApiService.managementGetNotificationsFileList(this.docId).subscribe((response: any) => {
                  this.dialogRef.close({elements: response});
                  this.matDialogService.closeAll();
                })
              }, () => {
                this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
                this.inactiveSubmit = false;
              });
            });
          });
          return
        }
        break
      case 'add-notification-filter':
        this.managementApiService.managementNotificationCreateFilter(this.managementService.collectObject(this.type, formData, this.formElements), this.docId).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(this.type)).subscribe(() => {
            this.managementApiService.managementGetNotificationListFilters(this.docId).subscribe((response: any) => {
              this.dialogRef.close({filters: response});
              this.matDialogService.closeAll();
            })
          });
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
          this.inactiveSubmit = false;
        });
        break
    }
  }

  ngOnInit() {
    switch (this.type) {
      case 'add-question':
      case 'edit-question':
        this.optionType$ = this.managementService.selectedType$.subscribe((res: any) => {
          this.optionType = res
          this.managementApiService.managementGetTypesOption(res).subscribe((response: any) => {
            if (this.type === 'edit-question') {
              this.deleteCopyArrFiles = [...this.arrFiles]
            }
            this.arrFiles = []
            this.controlsFile = response
          })
          return
        })
        break
      case 'add-question-attestation':
      case 'edit-question-attestation':
        this.optionType$ = this.managementService.selectedType$.subscribe((res: any) => {
          this.optionType = res
          this.managementApiService.managementGetTypesOptionAttestation(res).subscribe((response: any) => {
            if (this.type === 'edit-question-attestation') {
              this.deleteCopyArrFiles = [...this.arrFiles]
            }
            this.arrFiles = []
            this.controlsFile = response
          })
          return
        })
        break
      default:
        return;
    }
  }

  ngOnDestroy() {
    switch (this.type) {
      case 'add-question':
      case 'edit-question':
      case 'add-question-attestation':
      case 'edit-question-attestation':
        this.optionType$.unsubscribe()
        break
      default:
        return
    }
  }

}
