<div class="custom_dialog feedback">
  <div class="container-feedback">
    <div class="title">Редактировать</div>
    <form [formGroup]="formFeedback" class="form_filter" *ngIf="formFeedback">

      <mat-select class="custom_select for-feedback" placeholder="Выберите Отдел" (selectionChange)="paramsFilters('department')" formControlName="department">
        <mat-option [value]="null">--</mat-option>
        <mat-option
          *ngFor="let department of department"
          [value]="department.id">
          {{ department.title }}
        </mat-option>
      </mat-select>

      <ng-container *ngIf="this.formFeedback.value.department">
        <mat-select class="custom_select for-feedback" placeholder="Выберите Тему" (selectionChange)="paramsFilters('theme')" formControlName="theme">
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let theme of theme"
            [value]="theme.id">
            {{ theme.title }}
          </mat-option>
        </mat-select>
      </ng-container>

      <ng-container *ngIf="this.formFeedback.value.department && this.formFeedback.value.theme">
        <mat-select class="custom_select for-feedback" placeholder="Выберите Подтему" (selectionChange)="paramsFilters('subtheme')" formControlName="subtheme">
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let subtheme of subtheme"
            [value]="subtheme.id">
            {{ subtheme.title }}
          </mat-option>
        </mat-select>
      </ng-container>

      <ng-container>
        <mat-select class="custom_select for-feedback" placeholder="Модератор" formControlName="moderator">
          <ngx-mat-select-search
            [formControl]="searchFilterCtrl"
            placeholderLabel="Поиск"
            noEntriesFoundLabel="Нет результатов"
            [(ngModel)]="selectSearchUpdateString"
          ></ngx-mat-select-search>

          <mat-option
            *ngFor="let user of feedback.users | search:selectSearchUpdateString:'email'"
            [value]="user.id">
            {{ user.surname }} {{ user.name }} {{ user.email }}
          </mat-option>
        </mat-select>
      </ng-container>

    </form>

    <div class="button-container">
      <div class="button" [id]="'buttonTemplate'" (click)="updateFeedback()">РЕДАКТИРОВАТЬ</div>
      <div class="preloader" [id]="'preloaderTemplate'">
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

  </div>
</div>
