import {Component, OnInit, ViewChild} from '@angular/core'
import {FormControl, FormGroup} from "@angular/forms";
import {FeedbackManagementService} from "components/pages/feedback/feedback-management/feedback-management.service";
import {ExtraService} from "services";
import {Location} from "@angular/common";

@Component({
  selector: 'app-feedback-management',
  templateUrl: './feedback-management.component.html',
  styleUrls: ['./feedback-management.component.scss']
})

export class FeedbackManagementComponent implements OnInit {

  constructor(
    private feedbackManagementService: FeedbackManagementService,
    private location: Location,
    public extraService: ExtraService
  ) {
  }

  @ViewChild('workspace') workspace;

  searchStringDocument = '';

  requests = undefined;
  subtheme = undefined;
  department = undefined;
  theme = undefined;
  status = undefined;
  users: any;
  feedbackStatistics = undefined;

  formFeedback = new FormGroup( {
    'subtheme': new FormControl(null),
    'department': new FormControl(null),
    'theme': new FormControl(null),
    'status': new FormControl(null),
    'user': new FormControl(null),
    'moderator': new FormControl(null)
  })

  searchFeedback() {
    this.feedbackManagementService.managementFeedbackList(this.searchStringDocument, this.formFeedback.value.theme, this.formFeedback.value.department, this.formFeedback.value.subtheme, this.formFeedback.value.status).subscribe((response: any) => {
      this.requests = response;
    })
  }

  clearSearchFeedback() {
    this.searchStringDocument = '';
    this.searchFeedback()
  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.searchFeedback()
    this.feedbackManagementService.managementFeedbackParams().subscribe((response: any) => {
      this.subtheme = response.subtheme;
      this.department = response.department;
      this.theme = response.theme;
      this.status = response.status;
      this.users = response.users;
    })
    this.feedbackManagementService.managementFeedbackStatistics().subscribe( (response: any) => {
      this.feedbackStatistics = response
    })
    this.feedbackManagementService.$updated.subscribe(() => {
      this.searchFeedback()
    })
  }

}
